.carousel .thumbs-wrapper {
    margin: 0 !important;
}

.carousel .thumb {
    margin-right: 0;
}

.carousel .thumb.selected, .carousel .thumb:hover {
    border: none;
}

.carousel .thumbs-wrapper li.thumb {
    cursor: pointer;
}

.carousel .thumbs-wrapper li.thumb img {
    opacity: 0.5;
}

.carousel .thumbs-wrapper li.selected img {
    opacity: 1;
}